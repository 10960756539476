import React from 'react';
import {useTranslation} from 'react-i18next';
import {CamDigiKeyContext} from '../hooks/useCamDigiKey';
import axios from 'axios';
import WarningLottie from './WarningLottie';
import {isAndroid, isIOS, isMobile} from 'react-device-detect'

const QrCodeExpired = ({ loginToken, didRefreshSuccess, didRefreshError }) => {
  const [refreshing, setRefreshing] = React.useState(false)
  const { t } = useTranslation()

  const refreshQrCode = async () => {
    setRefreshing(true)
    try {
      const res = await axios.post('/api/v1/authenticate/refreshQRToken', {
        loginToken
      })
      didRefreshSuccess(res.data)
      setRefreshing(false)
    } catch (e) {
      didRefreshError(e)
    }
  }

  return ( refreshing
    ? <div className="absolute h-full w-full flex flex-col items-center justify-center bg-white bg-opacity-70">
        <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900">
        </div>
      </div>
    : <div className="absolute h-full w-full flex flex-col items-center justify-center bg-white bg-opacity-70">
        <p className="text-black tracking-wide uppercase text-sm">{t('error.qr_code_expired')}</p>
        <button className="mt-4 bg-blue-700 hover:bg-blue-600 text-white py-1 px-4 rounded-md transition duration-100 w-auto"
          onClick={refreshQrCode}>
          <small>{t('button.refresh_qr_code')}</small>
        </button>
      </div>
  )
}

const LoginSuccess = ({ redirection_name }) => {
  const { t } = useTranslation()

  return (
    <>
      <div className="absolute h-full w-full flex flex-col items-center justify-center bg-white bg-opacity-70">
        <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
          <svg className="h-6 w-6 text-green-600" fill="none" stroke="currentColor"
            viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 13l4 4L19 7" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"></path>
          </svg>
        </div>
        <p className="text-black tracking-wide uppercase font-bold pt-5 text-sm">
          {t('app.login.success')}
        </p>
      </div>

      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center" id="loading" style={{ zIndex: 99999 }}>
        <div className="relative mx-2 p-5 border w-96 shadow-lg rounded-md bg-white">
          <div className="mt-3 text-center">
            <div className="animate-spin rounded-full h-20 w-20 border-b-2 border-gray-900 mx-auto"></div>
            <h3 className="mt-5 mb-4 text-lg leading-6 font-medium text-gray-900">{t('app.login.success')}</h3>
            <p className="text-sm text-gray-500">
              <span>{t('app.login.redirecting_message')}<span className="font-bold">{redirection_name}</span></span>
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export const ErrorAlert = ({ error }) => {
  const { t } = useTranslation()

  const buttonGoBackOnClick = () => {
    window?.history.back()
  }

  return (
    <>
      <div className="absolute h-full w-full flex flex-col items-center justify-center bg-white bg-opacity-70">
        <p className="text-black tracking-wide uppercase text-sm">{t('error.invalid_qr_code')}</p>
      </div>
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center" style={{ zIndex: 99999 }}>
        <div className="relative mx-2 p-5 border w-96 shadow-lg rounded-md bg-white">
          <div className="mt-3 text-center">
            <div className="mx-auto" style={{ width: '168px' }}>
              <span className="md:block w-full">
                <WarningLottie />
              </span>
            </div>
            <h3 className="mt-5 mb-4 text-lg leading-6 font-medium text-gray-900">{t(error?.message)}</h3>
            <p className="text-sm text-gray-500">
              <span>{t(error?.description)}</span>
            </p>
            <div className="flex justify-center my-5 gap-2">
              <button onClick={() => window?.location?.reload()} className="bg-gray-100 hover:bg-gray-200 text-gray-600 py-1 px-4 rounded-md transition duration-100 w-auto">Reload</button>
              <button onClick={buttonGoBackOnClick} className="bg-red-500 hover:bg-red-400 text-white py-1 px-4 rounded-md transition duration-100 w-auto">{t('button.go_back')}</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default function QrCodeLoginView() {
  const { data, loginToken } = React.useContext(CamDigiKeyContext)
  const [qrCode, setQrCode] = React.useState(data?.qrCode)
  const [qrCodeToken, setQrCodeToken] = React.useState(data?.qrCodeToken)
  const [error, setError] = React.useState(null)
  const { t, i18n } = useTranslation()
  const PULL_INTERVAL = 5000
  let intervalRef = React.useRef()
  const statusCode = React.useMemo(() => {
    return {
      INVALID_LOGIN_TOKEN: 4000,
      QR_CODE_EXPIRED: 4001,
      LOGIN_TOKEN_EXPIRED: 4002,
      LOGIN_TOKEN_NOT_FOUND: 4004,
      LOGIN_TOKEN_REQUEST_FAILED: 4005,
      WAITING_FOR_QR_CODE_SCAN: 4400,
      LOGIN_SUCCESS: 0
    }
  }, [])
  const [status, setStatus] = React.useState(statusCode.WAITING_FOR_QR_CODE_SCAN)

  const checkLoginStatus = React.useCallback(async () => {
    const isRemember = document.getElementById("stayLoginCheckbox").checked
    try {
      const res = await axios.post('/api/v1/authenticate/checkLoginStatus', { 
        loginToken,
        qrCodeToken: qrCodeToken,
        isRemember
      })
      console.log("[STATUS] res =>", res.data)

      switch (res.data?.error) {
        case statusCode.LOGIN_SUCCESS:
          // window?.history?.replaceState(null, null, "/")
          setStatus(statusCode.LOGIN_SUCCESS)
          clearInterval(intervalRef?.current)
          const redirectUrl = res.data?.data?.responseURL
          console.log(redirectUrl);
          window?.location?.replace(redirectUrl)
          break
        case statusCode.INVALID_LOGIN_TOKEN:
          setError({
            message: 'error.invalid_login_token',
            description: 'error.invalid_login_token_description'
          })
          clearInterval(intervalRef?.current)
          break
        case statusCode.QR_CODE_EXPIRED:
          setStatus(statusCode.QR_CODE_EXPIRED)
          clearInterval(intervalRef?.current)
          break
        case statusCode.LOGIN_TOKEN_EXPIRED:
          setError({
            message: 'error.login_token_expired',
            description: 'error.login_token_expired_description'
          })
          clearInterval(intervalRef?.current)
          break
        case statusCode.LOGIN_TOKEN_NOT_FOUND:
          // Multiple Tabs Detected?
          setError({
            message: 'error.login_token_not_found',
            description: 'error.login_token_not_found_description'
          })
          clearInterval(intervalRef?.current)
          break
        case statusCode.LOGIN_TOKEN_REQUEST_FAILED:
          setError({
            message: 'error.login_token_request_failed',
            description: 'error.login_token_request_failed_description'
          })
          clearInterval(intervalRef?.current)
          break
        case statusCode.WAITING_FOR_QR_CODE_SCAN:
          // Do nothing, just waiting :P
          break
        default:
          console.log("[STATUS] Unknown Error =>", error)
          setError({
            message: 'error.unknown',
            description: 'error.unknown_description',
            // actions: ['RELOAD', 'GO_BACK']
          })
          clearInterval(intervalRef?.current)
      }
    } catch (e) {
      console.log("[STATUS] Something Went Wrong =>", e)
      setError({
        message: 'error.something_went_wrong',
        description: 'error.something_went_wrong_description'
      })
      clearInterval(intervalRef?.current)
    }
  }, [error, loginToken, qrCodeToken, statusCode])

  React.useEffect(() => {
    checkLoginStatus()
    const interval = setInterval(checkLoginStatus, PULL_INTERVAL)
    intervalRef.current = interval
    return () => clearInterval(intervalRef?.current)
    // eslint-disable-next-line
  }, [qrCodeToken])

  const didRefreshQrCodeSuccess = (res) => {
    console.log("Refresh QR CODE res =>", res)
    const error = res?.error
    if (error === 0) {
      setQrCode(res?.data?.qrCodeImage)
      setQrCodeToken(res?.data?.qrCodeToken)
      setStatus(statusCode.WAITING_FOR_QR_CODE_SCAN)
      /** No need to create new interval, new interval will call in `useEffect` after `QrCodeToken` changed by setState */
      // const interval = setInterval(checkLoginStatus, PULL_INTERVAL)
      // intervalRef.current = interval
    } else if (error === statusCode.LOGIN_TOKEN_EXPIRED) {
      setError({
        message: 'error.login_token_expired',
        description: 'error.login_token_expired_description'
      })
    } else if (error === statusCode.INVALID_LOGIN_TOKEN) {
      setError({
        message: 'error.invalid_login_token',
        description: 'error.invalid_login_token_description'
      })
    }
  }

  const didRefreshQrCodeError = (err) => {
    setError({
      message: 'error.something_went_wrong',
      description: 'error.something_went_wrong_description'
    })
  }

  return (
    <div className="w-full max-w-sm">
      <div className="rounded-lg border border-gray-100 py-6 px-4 shadow-2xl bg-white ">
        <div className='flex justify-center'>
          <img draggable={false} alt={data?.clienDomain} title={data?.clienDomain} 
            className='aspect-square object-cover w-14 h-14 rounded-md'
            src={`data:image/png;base64,${data?.clientLogo}`} />
        </div>

        <h3 className="text-lg font-semibold text-center text-gray-800 mt-3">
          { i18n.language === 'en' ? data?.clientNameEn : data?.clientNameKh} {" "}
          <span className='font-normal text-lg mb-9 mt-1 text-gray-500'>
            {t('app.access_message.first')}
            <a className="font-normal hover:underline hover:cursor-pointer text-blue-400" href={data?.clientPrivacyPolicyURL} rel="noopener noreferrer" target="_blank">
              {t('app.access_message.second')}
            </a>
            {t('app.access_message.third')}
          </span>
        </h3>

        <p className="text-center text-sm text-gray-500 mt-4 mx-8 leading-relaxed">
          <span dangerouslySetInnerHTML={{ __html: t('app.instruction') }} />
          
          <span className="ml-2 cursor-pointer inline-block absolute text-blue-300" id="buttonShowInstruction">
            <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path clipRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" fillRule="evenodd" />
            </svg>
          </span>
        </p>

        <div className="relative h-60 w-60 border-2 mx-auto border-blue-500 rounded-md text-center mt-4 overflow-hidden">
          <div className="absolute w-full p-1">
            <img draggable={false} alt="CamDigiKey QR Code"
              className={`w-full${status === statusCode.QR_CODE_EXPIRED || error || status === statusCode.LOGIN_SUCCESS ? ' blur-sm' : ''}`}
              src={`data:image/png;base64,${qrCode}`} />
          </div>
          
          {/* Refresh QR */}
          { status === statusCode.QR_CODE_EXPIRED && !error
            ? <QrCodeExpired
                loginToken={loginToken}
                didRefreshSuccess={didRefreshQrCodeSuccess}
                didRefreshError={didRefreshQrCodeError}
              /> : null }
          
          { status === statusCode.LOGIN_SUCCESS 
            ? <LoginSuccess redirection_name={ i18n.language === 'en' ? data?.clientNameEn : data?.clientNameKh} /> : null }
          
          { error ? <ErrorAlert error={error} /> : null }
        </div>

        { isMobile && (isAndroid || isIOS) ? (
          <div className="flex flex-col gap-4 items-center">
            <p className="text-center text-sm text-gray-500 mt-4 mx-8 leading-relaxed">{t('app.deeplink_login_instruction')} <strong>CamDigiKey</strong></p>
              <button
                className="p-2 w-60 rounded-lg text-white bg-blue-600 hover:bg-blue-700 shadow-md shadow-bg-blue-500 disabled:bg-gray-300 disabled:text-gray-50"
                disabled={status === statusCode.QR_CODE_EXPIRED && !error}
                onClick={() => {
                  window.location.href = `camdigikey://sso_deeplink?qrCodeToken=${encodeURIComponent(qrCodeToken)}&nameEn=${encodeURIComponent(data?.clientNameEn)}&nameKh=${encodeURIComponent(data?.clientNameKh)}`
                  // window.location.href = 'camdigikey://login?token=' + encodeURIComponent(loginToken)
                }}
              >
                {t('app.deeplink_login_button')}
              </button>
          </div>
        ) : null }

        <div className="flex justify-center mb-6 mt-3">
          <label className="flex items-center">
            <input className="peer sr-only" id="stayLoginCheckbox" type="checkbox" />
            <svg className="h-6 w-6 peer-checked:hidden block text-gray-300 cursor-pointer"
              fill="none" stroke="currentColor"
              viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2" />
            </svg>
            <svg className="h-6 w-6 hidden peer-checked:block text-blue-500 cursor-pointer"
              fill="currentColor"
              viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path clipRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                fillRule="evenodd" />
            </svg>
            <span className="ml-1 select-none text-gray-500 text-sm stay_login cursor-pointer">{t('app.stay_login')}</span>
          </label>

          <div className="relative flex flex-col items-center group">
            <span className="inline-block m-1 text-blue-300 cursor-pointer">
              <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path clipRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" fillRule="evenodd" />
              </svg>
            </span>

            <div className="hidden group-hover:flex">
              <div className="absolute -right-14 bottom-8 flex flex-col items-center">
                <div className="relative z-10 p-2 text-xs text-white whitespace-no-wrap bg-black shadow-lg w-60 flex">
                  <div className="mr-2">
                    <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path clipRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" fillRule="evenodd" />
                    </svg>
                  </div>
                  <span>{t('app.stay_login_help')}</span>
                </div>
                <div className="w-3 h-3 -mt-2 mr-16 rotate-45 bg-black self-end"></div>
              </div>
            </div>

          </div>
        </div>

        

        <p className="w-full mt-4 text-sm text-center text-gray-400">
          <span dangerouslySetInnerHTML={{__html: t('app.aggreement')}} />
        </p>
      </div>
    </div>
  );
}